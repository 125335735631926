export const Disclaimer = () => {
  return (
    <>
      <p>
        Zolo provides secure data erasure for data-bearing devices such as
        laptops, desktop PCs, hard drives, servers, mobile phones, and tablets.
        We use Blancco, an industry-leading data sanitisation solution certified
        to meet NIST 800-88, ISO 27040, and Common Criteria standards, in
        accordance with our ISO-certified processes: ISO 27001:2013, ISO
        14001:2015, AS/NZS 5377:2013, and ISO 9001:2015.
      </p>
      <br />
      <p>
        For items such as SIM cards, e-SIMs, software licenses, and manufacturer
        warranty subscriptions (e.g., AppleCare), clients remain responsible for
        their removal or cancellation.
      </p>
      <br />
      <p>
        If you require additional services beyond data erasure, collection &
        recycling, such as checking for SIM cards, e-SIMs, software license
        checks, or manufacturer warranty assessments, please let us know in the
        additional info section, and we will provide more information.
      </p>
    </>
  );
};
