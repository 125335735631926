import { useEffect, useState } from "react";
import { DropoffService } from "../../../service/company/dropoff.service";
import { Center, Divider, Flex, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { DropoffSource } from "../../../models/enum/dropoff.enum";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { FileUploadInput } from "../../form/controls/file-upload.input";
import { ellipsesText } from "../../../util/string.util";
import { errorToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { FormTitle } from "../../form/form.title";
import GooglePlacesDropdown from "../../form/controls/google-places-dropdown.input";
import { Disclaimer } from "../../shared/Disclaimer";

const defaultState = {
  name: "",
  formData: {},
  uploaded: false,
};

export const DropoffModal = ({ isOpen, onClose, onSubmit }) => {
  const dropoffService = new DropoffService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [addressState, setAddressState] = useState({} as any);

  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState(defaultState);
  const [csvData, setCsvData] = useState(null as any);

  const [addressDirty, setAddressDirty] = useState(false);
  const [googlePlaces, setGooglePlaces] = useState({
    text: "",
    isValid: false,
  } as { text: string; isValid: boolean });

  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [moreInfoRequested, setMoreInfoRequested] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "address") {
      setGooglePlaces({ text: value, isValid: true });
      setAddressState(value);
      return;
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onGooglePlacesTextChange = (e: any) => {
    const text = e.target.value;
    setAddressDirty(true);

    // Input is invalid until the onPlaceSelected is fired
    setGooglePlaces({ text, isValid: false });
  };

  const onFileUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    setCsvData(formData);
    setUploadState({
      name: file[0].name,
      formData: formData,
      uploaded: true,
    });
  };

  const handleClose = () => {
    setFormState({});
    setUploadState(defaultState);
    setCsvData(null);
    setDisclaimerAccepted(false);
    setDisclaimerOpen(false);
    onClose();
  };

  const hasData = csvData || formState.manualList;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!hasData) {
      toast(
        errorToast("Please upload a file or provide a typed list of items.")
      );
      setLoading(false);
      return;
    }

    if (csvData) {
      formState.source = "-";
      // if (!formState.source) {
      //   toast(errorToast("Please select a data source."));
      //   setLoading(false);
      //   return;
      // }
    }

    if (formState.manualList) {
      formState.notes =
        (formState.notes ? `${formState.notes}\n\n` : "") +
        "Manual List\n\n" +
        formState.manualList;
      delete formState.manualList;
    }

    if (addressState) {
      formState.address = addressState;
    }

    const data = csvData ?? new FormData();
    data.append("bodyData", JSON.stringify(formState));

    await dropoffService
      .post(data)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
        onSubmit();
      });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <Modal
        width="600px"
        isOpen={isOpen}
        header="Request a pick-up"
        onClose={handleClose}
      >
        <Loading loading={loading} fill ignoreDelay />
        <form onSubmit={handleSubmit}>
          <Flex flexDir="column" gap="12px">
            <DatepickerInput
              required
              title="Preferred pick-up date"
              name="requestDate"
              onChange={onInputChange}
            />

            <GooglePlacesDropdown
              name="address"
              placeholder="Enter the pick-up location..."
              title="Pick-up address (optional)"
              isInvalid={!googlePlaces.isValid && addressDirty}
              invalidInputMessage="Please enter a valid address"
              onPlaceSelected={onInputChange}
              onChange={onGooglePlacesTextChange}
            />

            <Divider />

            <FileUploadInput
              uploadedLabel={ellipsesText(uploadState.name, 16)}
              type="document"
              onDrop={onFileUpload}
              accept={{
                "text/csv": [".csv"],
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
              maxFiles={1}
              isUploaded={uploadState.uploaded}
              additionalText="We only accept .csv, .xlsx, .xls"
              invalidInputMessage="Please take a photo or upload one."
            />

            {/* {uploadState.uploaded && (
              <DropdownInput
                required
                title="Data source"
                name="source"
                options={DropoffSource}
                onChange={onInputChange}
              />
            )} */}

            <Center>
              <FormTitle>- OR -</FormTitle>
            </Center>

            <TextAreaInput
              required={!csvData}
              title="What are you disposing of?"
              name="manualList"
              onChange={onInputChange}
            />

            <Divider />

            <TextAreaInput
              title="Additional info"
              name="notes"
              onChange={onInputChange}
            />

            <Flex gap="12px" justifyContent="flex-end">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>

              {disclaimerAccepted && (
                <ButtonCustom type="submit" disabled={!hasData}>
                  Confirm Pick-up
                </ButtonCustom>
              )}

              {!disclaimerAccepted && (
                <ButtonCustom
                  type="button"
                  disabled={!hasData}
                  onClick={() => setDisclaimerOpen(true)}
                >
                  Confirm
                </ButtonCustom>
              )}
            </Flex>
          </Flex>
        </form>
      </Modal>
      <Modal
        isOpen={disclaimerOpen}
        onClose={() => setDisclaimerOpen(false)}
        header="Disclaimer"
      >
        {moreInfoRequested ? (
          <p>
            Simply email <a href="mailto:support@zolo.com.au" style={{ color: "blue" }}>support@zolo.com.au</a> and one of our team representatives will contact you shortly.
            <br />
            For convenience, simply copy/paste the email below:
            <br />
            <br />
            To: <a href="mailto:support@zolo.com.au" style={{ color: "blue" }}>support@zolo.com.au</a>
            <br />
            Subject: Zolo Collection Enquiry
            <br />
            <br />
            Hi Zolo,
            <br />
            <br />
            I'd like to chat to someone about your terms & conditions please
          </p>
        ) : (
          <Disclaimer />
        )}

        <br />

        <Flex gap="12px" justifyContent="flex-end">
          <ButtonCustom
            secondary
            neutral
            onClick={() => setMoreInfoRequested(!moreInfoRequested)}
          >
            {moreInfoRequested ? "Back" : "I need more info"}
          </ButtonCustom>

          {!moreInfoRequested && (
            <ButtonCustom
              onClick={() => {
                setDisclaimerAccepted(true);
                setDisclaimerOpen(false);
              }}
            >
              Accept Terms & Conditions
            </ButtonCustom>
          )}
        </Flex>
      </Modal>
    </>
  );
};
